var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: _vm.showHidden ? "Hidden Vehicle Type" : "Vehicle Type",
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("vehicle-type-form", {
                    ref: "vehicleTypeForm",
                    attrs: { value: _vm.editedItem, tripTypes: _vm.tripTypes },
                    on: { submit: _vm.saveItem, closeForm: _vm.closeForm },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: `item.synced`,
          fn: function ({ item }) {
            return [
              item.synced
                ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-sync")])
                : _vm._e(),
            ]
          },
        },
        {
          key: `item.staffUse`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.staffUse ? "mdi-check" : "mdi-close")),
              ]),
            ]
          },
        },
        {
          key: `item.autoAssign`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.autoAssign ? "mdi-check" : "mdi-close")),
              ]),
            ]
          },
        },
        {
          key: `item.overrideRate`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.overrideRate ? "mdi-check" : "mdi-close")),
              ]),
            ]
          },
        },
        {
          key: `item.vehicleRate`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  _vm._s(
                    item.overrideRate ? _vm.toMoney(item.vehicleRate) : "N/A"
                  )
                ),
              ]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }